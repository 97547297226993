body,
html,
.root {
    height: 100%;
}

.full {
    width: 100vw;
    height: 100vh;
}

.readonly-field,
.readonly-field:focus {
    background-color: #f1f1f1;
}

.box {
    border: 1px solid #dee2e6;
    border-radius: 5px;
}

.noscroll {
    height: 100vh;
    height: -webkit-fill-available;
    max-height: 100vh;
    overflow-x: auto;
    overflow-y: hidden;
}

.scroll {
    height: 100vh;
    overflow-x: auto;
}

.loading-container-show {
    width: 100vw;
    height: 100%;
    background: rgba(209, 214, 221, 0.7);
    position: fixed;
    left: 0px;
    top: 0px;
    bottom: 0px;
    z-index: 999999;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    cursor: wait;
}

.loading-body {
    text-align: center;
    width: 100vw;
}

.loading-message {
    font-weight: bold;
    font-size: 16px;
    width: 100vw;
    text-align: center;
}

.loading-container {
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
}

.background-container {
    width: 100vw;
    height: 50vh;
    position: absolute;
    left: 0px;
    top: 0px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-end;
    padding-top: 50px;
}

.card-representante .card-header {
    background-color: #FFF;
    border: 0px;
    padding-bottom: 0px;
    margin-bottom: 0px;
}

.card-representante .card-title {
    font-size: 16px;
    margin-bottom: 5px;
}

.card-representante .card-subtitle {
    font-size: 12px;
}

.card-representante .card-body {
    font-size: 12px;
    padding: 10px;
}

.card-representante .card-footer {
    font-size: 14px;
    padding: 2px;
}

.table-busca-cidades {
    max-height: 200px;
    overflow-y: auto;
    border: 1px solid #e7e9ec;
}

.page-login {
    background-color: #e7e9ec;
    width: 100vw;
    height: 100vh;
    text-align: center !important;

    display: flex;
    align-items: center;
    padding-top: 40px;
    padding-bottom: 40px;
    background-color: #f5f5f5;
}

.form-login-hide {
    transition: max-height 1.0s, overflow 0s;
}

.form-login {
    overflow: auto;
    transition: max-height 0.5s, overflow 0.5s 0.5s;
}

.slide-bar-app {
    width: 280px;
}

/*body {
    display: flex !important;
}*/

.cursor-copy {
    cursor: copy;
}

.modal-confirmacao-danger {
    background-color: #ffe3da;
}

.modal-confirmacao-success {
    background-color: #c9ffe6;
}

.textarea-configuracao {
    height: 200px !important;
}

.form-check-datagrid {
    width: 1.2em;
    height: 1.2em;
    border-radius: 0px !important;
}

.item-selected-datagrid {
    border-left: 5px solid #d0b100;
}

.item-no-selected-datagrid {
    border-left: 5px solid #F1F1F1;
}

.tr-borderless td {
    border-bottom-width: 0px;
    padding-top: 7px;
    padding-bottom: 5px;
}

.fs-6-5 {
    font-size: 0.800rem;
}

.fs-7 {
    font-size: 0.800rem;
}

.fs-7-5 {
    font-size: 0.750rem;
}

.fs-8 {
    font-size: 0.700rem;
}

.fs-9 {
    font-size: 0.600rem;
}

.fs-10 {
    font-size: 0.500rem;
}

.btn-bdm {
    background-color: #00b542;
    border-color: #00b542;
    color: #fff;
}

.btn-outline-bdm {
    border-color: #00b542;
    color: #00b542;
}

.btn-outline-bdm:hover {
    background-color: #000000 !important;
    border-color: #00b542 !important;
    color: #00b542 !important;
}

.bg-bdm {
    background-color: #00b542 !important;
}

.bg-orange {
    background-color: #ed561b !important;
}

.bg-green {
    background-color: #50B432 !important;
}

.bg-blue {
    background-color: #058DC7 !important;
}

.text-bdm {
    color: #00b542 !important;
}

.tree-view {}

.tree-view .parent {
    list-style-type: none;
}

.tree-view .child {
    list-style-type: none;
    cursor: pointer;
    user-select: none;
}

.element-with-child {
    cursor: pointer;
    -webkit-user-select: none;
    /* Safari 3.1+ */
    -moz-user-select: none;
    /* Firefox 2+ */
    -ms-user-select: none;
    /* IE 10+ */
    user-select: none;
}

.element-with-child .element-selected {
    font-weight: bold;
}

.form-check-input-size-20 {
    font-size: 20px;
}

/*
  .element-with-child::before {
    content: "\25B6";
    color: black;
    display: inline-block;
    margin-right: 6px;
  }
  
  .caret-down::before {
    -ms-transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);  
  }
*/


.painel-dashboard {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.painel-dashboard .titulo {
    font-size: 20px;
    color: darkgrey;
}

.painel-dashboard .valor {
    font-size: 6.5em;
    line-height: 1;
}

.center-cropped {
    width: 250;
    height: 250px;
    background-position: center center;
    background-repeat: no-repeat;
}

.dropdown-in {
    position: static !important;
}

.btn-menu-dropdown-checkbox {
    overflow: auto;
    overflow-x: hidden;
    border: 1px solid #ced4da;
    color: #212529;
    background-color: #fff;
}

.btn-menu-dropdown-checkbox:focus,
.btn-menu-dropdown-checkbox:hover,
.btn-menu-dropdown-checkbox .btn-check:checked+.btn,
.btn-check:active+.btn,
.btn:active,
.btn.active,
.btn.show {
    border: 1px solid #ced4da !important;
    color: #212529 !important;
    background-color: #fff !important;
}

.rotated {
    transform: rotate(-90deg);
    /* Equal to rotateZ(45deg) */
    background-color: pink;
}

.rotated-th {
    height: 110px;
    position: relative;
}

.rotated-th__label-45 {
    bottom: 5px;
    left: 50%;
    position: absolute;
    transform: rotate(-45deg);
    transform-origin: center left;
    white-space: nowrap;
}

.rotated-th__label-90 {
    bottom: 5px;
    left: 50%;
    position: absolute;
    transform: rotate(-90deg);
    transform-origin: center left;
    white-space: nowrap;
}



.text-bg-orange {
    background-color: #FF7733;
}

.pointer {
    cursor: pointer;
}

.form-label-app {
    margin-bottom: 0.0rem !important;
}


.grid-item {}

.grid-item-label {
    font-size: .875rem;
    font-weight: bold;
}

.grid-item-text {
    font-size: .875rem;
}


.list-group-mural .active {
    background-color: #f1f1f1;
    color: #000000;
}

.list-group-mural .nao-lido {
    font-weight: bolder;
}

.list-group-mural .lido {
    font-weight: normal;
}

.btn-lateral .texto {
    display: none;
}

.btn-lateral:hover .texto {
    display: inline;
}

.dropdown-menu-app .dropdown-item {
    padding-top: 1px !important;
    padding-bottom: 1px !important;
}

.dropdown-menu-app .dropdown-item:hover {
    color: #FFFFFF;
    text-decoration: none;
    background-color: #0E6EFD;
}

tr.td-text-center-1>td:nth-child(1) {
    text-align: center;
}

tr.td-text-center-2>td:nth-child(2) {
    text-align: center;
}

tr.td-text-center-3>td:nth-child(3) {
    text-align: center;
}

tr.td-text-center-4>td:nth-child(4) {
    text-align: center;
}

tr.td-text-center-5>td:nth-child(5) {
    text-align: center;
}

tr.td-text-center-6>td:nth-child(6) {
    text-align: center;
}

tr.td-text-center-7>td:nth-child(7) {
    text-align: center;
}

tr.td-text-center-8>td:nth-child(8) {
    text-align: center;
}

tr.td-text-center-9>td:nth-child(9) {
    text-align: center;
}

tr.td-text-center-10>td:nth-child(10) {
    text-align: center;
}

tr.tr-totalizador:last-child {
    font-weight: bold;
}

.blink-me {
    animation: blinker 1s linear infinite;
}

@keyframes blinker {
    50% {
        opacity: 0;
    }
}

.thumb-image-biblioteca-card {
    object-fit: cover;
    /* keep aspect ratio */
    object-position: 100% 0;
    width: 100%;
    height: 200px;
}

.thumb-image-biblioteca-table {
    width: 32px;
    height: 32px;
}

.responsive-iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 97%;
}

.form-checkbox-input-indeterminate {
    background-color: #b9d1f5;
    border-color: #0d6efd;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
}

.form-checkswitch-input-indeterminate {
    background-color: #b9d1f5;
    border-color: #0d6efd;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
}


.menssagem-enviada {
    background-color: #ddffc0 !important;
}

.chat {
    height: calc(100vh - 15rem);
}

/*https://uipencil.com/2023/02/20/creating-css-bubble-dialog-boxes-with-arrow-styling-a-complete-guide-with-examples-for-all-arrow-directions/*/

/*Dialog box*/
.dialog_box {
    position: relative;
    padding: 8px;
    background: #f4f4f4;
}

.dialog_box:after,
.dialog_box:before {
    border: solid transparent;
    content: "";
    height: 0;
    width: 0;
    position: absolute;
}

.dialog_box:after {
    border-width: 4px;
}

.dialog_box:before {
    border-width: 8px;
}

/*Arrow pointing LEFT*/
.dialog_box.left:after,
.dialog_box.left:before {
    right: 100%;
    top: 25%;
}

.dialog_box.left:after {
    border-color: transparent #FFFFFF transparent transparent;
    margin-top: -4px;
}

.dialog_box.left:before {
    border-color: transparent #FFFFFF transparent transparent;
    margin-top: -8px;
}

/*Arrow pointing RIGHT*/
.dialog_box.right:after,
.dialog_box.right:before {
    left: 100%;
    top: 25%;
}

.dialog_box.right:after {
    border-color: transparent transparent transparent #ddffc0;
    margin-top: -4px;
}

.dialog_box.right:before {
    border-color: transparent transparent transparent #ddffc0;
    margin-top: -8px;
}

.ms-6 {
    margin-left: 2.5rem !important
}

.ms-7 {
    margin-left: 3rem !important
}

.ms-8 {
    margin-left: 3.5rem !important
}

.ms-9 {
    margin-left: 4rem !important
}

.ms-10 {
    margin-left: 4.5rem !important
}

.ms-11 {
    margin-left: 5rem !important
}

.ms-12 {
    margin-left: 6rem !important
}

.ms-13 {
    margin-left: 6.5rem !important
}

.ms-14 {
    margin-left: 7rem !important
}

.ms-15 {
    margin-left: 7.5rem !important
}

.ms-auto {
    margin-left: auto !important
}

.me-6 {
    margin-right: 2.5rem !important
}

.me-7 {
    margin-right: 3rem !important
}

.me-8 {
    margin-right: 3.5rem !important
}

.me-9 {
    margin-right: 4rem !important
}

.me-10 {
    margin-right: 4.5rem !important
}

.me-11 {
    margin-right: 5rem !important
}

.me-12 {
    margin-right: 6rem !important
}

.me-13 {
    margin-right: 6.5rem !important
}

.me-14 {
    margin-right: 7rem !important
}

.me-15 {
    margin-right: 7.5rem !important
}

.me-auto {
    margin-right: auto !important
}

.circleStatus {
    width: 30px !important;
    height: 30px !important;
    border-radius: 20px;
}

.h100-calc {
    height: calc(100vh - 15rem);
}

.h100-calc-20 {
    height: calc(100vh - 20rem);
}

.h100-calc-25 {
    height: calc(100vh - 25rem);
}

.breadcrumb-chevron {
    --bs-breadcrumb-divider: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='%236c757d'%3E%3Cpath fill-rule='evenodd' d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3E%3C/svg%3E");
}


.btn-group-custom-primary label {
    border: 1px solid #0a58ca !important;
}

.btn-group-custom-dark label {
    border: 1px solid #000000 !important;
}

.btn-link-clear {
    color: #000000 !important;
    text-decoration: none !important;
    border: none !important;
    border-color: transparent !important;
    outline: none;

}

.btn-link-clear:hover {
    color: #000000 !important;
    text-decoration: none !important;
}

.btn-link-clear:focus {
    border: none !important;
    border-color: transparent !important;
    outline: none;
}


.btn-link-clear:focus,
.btn-link-clear:hover,
.btn-link-clear {
    border: none !important;
    background-color: transparent !important;
    border-color: transparent !important;
    outline: none;
    color: #000000 !important;
    text-decoration: none !important;
}