
@import url('./theme.css');

.wrapper {
  flex-grow: 1;
  height: 100%;
}

/* entrada */
.react-flow__handle.target {
  background-color: #3CA23C !important;
  border: 1px solid #124712;
}

/* saida */
.react-flow__handle.source {
  background-color: #f59d4a !important;
  border: 1px solid #ff6600;
}

/* saida */
.react-flow__handle.source-sim {
  background-color: #a6f1a6 !important;
  border: 1px solid #3CA23C;
}

.react-flow__handle.source-nao {
  background-color: #f1a6a6 !important;
  border: 1px solid #ff0000;
}

.edge-label-sim {
  color:#3CA23C;
  font-weight: bold;
  font-size: 10px;
}

.edge-label-nao {
  color:#ff0000;
  font-weight: bold;
  font-size: 10px;
}

/* Context Node Menu */

.context-node-menu {
  background: white;
  border-style: solid;
  border: 1px solid black;
  box-shadow: 10px 19px 20px rgba(0, 0, 0, 10%);
  position: absolute;
  z-index: 10;  
}

.context-node-menu button {
  border: none;
  display: block;
  padding: 0.5em;
  padding-bottom: 0.3em;
  padding-top: 0.3em;
  text-align: left;
  width: 100%;
}
 
.context-node-menu button:hover {
  background: white;
}

/* Context Node Menu */

.context-panel-menu {
  background: white;
  border-style: solid;
  border: 1px solid black;
  box-shadow: 10px 19px 20px rgba(0, 0, 0, 10%);
  position: absolute;
  z-index: 10;
}

.context-panel-menu button {
  border: none;
  display: block;
  padding: 0.5em;
  padding-bottom: 0.3em;
  padding-top: 0.3em;
  text-align: left;
  width: 100%;
}

.context-panel-menu hr {
  border: 1px solid black;
  display: block;
  padding: 0px;
  margin: 0px;
  width: 100%;
}
 
.context-panel-menu button:hover {
  background: white;
}

/* Circle Node */
 
.react-flow__node-circle {
  border-radius: 50% !important;
  height: 100px !important;
  width: 100px !important;
  font-family: monospace !important;
  text-align: center !important;
}

/* Start Node */
 
.react-flow__node-start {
  border-radius: 50% !important;
  font-family: monospace !important;
  text-align: center !important;
  background-color: #a6f1a6;
  border:1px solid #3CA23C;
}

/* End Node */
 
.react-flow__node-end {
  border-radius: 50% !important;
  font-family: monospace !important;
  text-align: center !important;
  background-color: #ff3333;
  border: 1px solid #F95354;
}

/* Condition Node */

.react-flow__node-condition {
  background-color: transparent !important;
  border-radius: 0% !important;
  padding: 0px !important;
  margin: 0px !important;
  box-shadow: none !important;
  border: 0px;
}
 
.react-flow__node-condition .node-label {  
  font-family: monospace !important;
  background-color: #FFA333;  
  clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Annotation Node */
 
.react-flow__node-annotation {
  font-size: 16px;
  width: 200px;
  color: #683bfa;
  position: absolute;
  box-shadow: none;
  font-family: monospace;
  text-align: left;
  background-color: transparent;
  border: none;
  padding: 0px;
}
 
.react-flow__node-annotation .annotation-content {
  padding: 0px;
  display: flex;
  white-space: pre-line
}
 
.react-flow__node-annotation .annotation-level {
  margin-right: 4px;
}
 
.react-flow__node-annotation .annotation-arrow {
  position: absolute;
  font-size: 24px;
}

/* Button Edge */

.react-flow__edges{
  z-index: 9998;  
}
 
.button-edge__label {
  position: absolute;
  pointer-events: all;
  transform-origin: center;
  z-index: 9999;
}
 
.button-edge__button {
  width: 30px;
  height: 30px;
  border: 0px solid #f7f9fb;
  color: var(--xy-edge-node-color-default);
  background-color: #f3f3f4;
  cursor: pointer;
  border-radius: 50%;
  font-size: 12px;
  padding-top: 0px;
  z-index: 9999;
}
 
.button-edge__button:hover {
  background-color: var(--xy-theme-hover);
  color: #ffffff;
}
 
.react-flow__edge-textbg {
  fill: #f7f9fb;
}

.react-flow__node-group div{
  width: 100%;
  height: 100%;
  color:#868686;
  padding:1px !important;
}

