:root {
    --building-color: #FF9800;
    --house-color: #0288D1;
    --shop-color: #7B1FA2;
    --warehouse-color: #558B2F;
  }  

.marker-mapa {
  background-color: #4285F4;
  border-radius: 28px;
  color: #FFFFFF;
  font-size: 14px;
  padding: 3px 3px;  
  position: relative;
}

.marker-mapa::after {
  content: "";
  position: absolute;
  left: 50%;
  top: 89%;
  transform: translate(-50%, 0);
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid currentColor;
}

.marker-mapa img {
  width: 24px;
  padding: 2px;
  margin: 0px;
  border-radius: 24px;
  background-color: #FFFFFF;
}

.marker-mapa div {
  width: 24px;
  padding: 2px;
  margin: 0px;
  border-radius: 24px;
  background-color: #FFFFFF;
}